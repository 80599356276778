import React from "react";
import moment from "moment";
import { ButtonIcon, Modal, Loader } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";

import "./style.css";

import FormEngine from "./FormEngine";
import ContactLink from "../ContactLink";
import IframeWindTransfer from "../../general/IframeWindTransfer";
import Button from "../../other/Button";

import {
  getSelfcareWindtransferExpertiseSolution,
  insertPdfWebservice,
} from "../../../requests/folder";

import AuthContext from "../../../context/AuthContext";

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModal: false,
      isModalWindTransfer: false,
      urlWindTransfer: null,
      isModalFormEngine: false,
    };
    this.cookies = new Cookies();
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    if (this.props.statusId && this.props.dataTimeLine.length) {
      const name = `alert-${this.context.token}-${this.props.statusId}`;
      if (!this.cookies.get(name)) {
        const resultCheckAlert = this.checkAlert();
        if (!this.props.isAlert && resultCheckAlert.count) {
          resultCheckAlert.onOpen();
          this.props.openPopup();
        }
      }
    }
  }

  checkAlert = () => {
    let result = {
      onOpen: () => {
        if (result.count)
          this.setState({ isModal: true }, () => {
            const name = `alert-${this.context.token}-${this.props.statusId}`;
            this.cookies.set(name, "1", { path: "/" });
          });
      },
      title: "POPUP",
      text: "",
      count: 0,
      data: [],
    };

    if (this.props.statusId) {
      result.title = this.context.sinister.statusNav;

      // CLIENT
      if (this.context.source === "client") {
        if ([2, 61, 5].includes(this.props.statusId)) {
          if ([55].includes(this.props.statusId)) {
            result.text = (
              <>
                Vous pouvez nous contacter pour définir une date d'expertise
                terrain :{" "}
                <ContactLink
                  folderId={this.context.id}
                  toName={this.context.agency.name}
                  toId={this.context.agency.id}
                  toSource={"agency"}
                  text="Contacter"
                  type="other"
                  callback={() => {
                    this.props.insertSituationIxperience();
                    this.setState({ isModal: false }, this.props.onClosePopup);
                  }}
                />
              </>
            );
            result.count++;
          } else {
            result.onOpen = () => {
              const name = `alert-${this.context.token}-${this.props.statusId}`;
              this.cookies.set(name, "1", { path: "/" });
              this.props.openExpertiseSolution();
            };
            result.count++;
          }
        } else if ([16, 17].includes(this.props.statusId)) {
          result.text = (
            <>
              Nous sommes en attente de documents pour traiter votre dossier.
              Nous vous invitons à nous les faire parvenir en cliquant ici :{" "}
              <ContactLink
                folderId={this.context.id}
                toName={this.context.agency.name}
                toId={this.context.agency.id}
                toSource={"agency"}
                text="Envoyer les documents"
                type="other"
                callback={() => {
                  this.props.insertSituationIxperience();
                  this.setState({ isModal: false }, this.props.onClosePopup);
                }}
              />
            </>
          );

          result.count++;
        } else if ([7].includes(this.props.statusId)) {
          result.text =
            "Nous sommes en attente des éléments pour réaliser l’expertise à distance de votre véhicule. Si ce n’est pas déjà fait, merci de vous rendre chez votre réparateur pour qu’il puisse prendre les photos de votre véhicule.";
          result.count++;
        } else if (
          [14].includes(this.props.statusId) &&
          this.context.sinister.dateWorkEnd &&
          moment().isAfter(
            moment(this.context.sinister.dateWorkEnd, "DD/MM/YYYY")
          )
        ) {
          result.text = "ATTFAC";
          result.data = [
            {
              type: "radio",
              text: "Votre véhicule est-il déjà réparé ?",
              1: 1,
              0: 3,
            },
            {
              type: "radio",
              text: "Êtes-vous en possession de la facture des réparations ?",
              1: 2,
              0: 5,
            },
            {
              type: "document",
              text: "Merci de nous la déposer ci-dessous",
            },
            {
              type: "radio",
              text: "Souhaitez-vous faire remettre en état votre véhicule par le réparateur ?",
              1: 4,
              0: 6,
            },
            {
              type: "radio",
              text: "Le véhicule est-il en cours de réparation ?",
              1: 5,
              0: 5,
            },
            {
              type: "text",
              text: "Merci de l’information, nous restons dans l’attente de la facture des réparations",
              1: 1,
              0: 5,
            },
            {
              type: "radio",
              text: "Souhaitez-vous remettre en état le véhicule vous-même ?",
              1: 7,
              0: 7,
            },
            {
              type: "text",
              text: "Merci de l’information, nous prévenons l’expert pour qu’il puisse communiquer le rapport d’expertise à votre assurance afin que vous soyez remboursé si les conditions de votre contrat le permettent",
            },
          ];
          result.title =
            "Nous sommes en attente de la facture des réparations.";
          result.count++;
          result.onOpen = () =>
            this.setState({ isModalFormEngine: true }, () => {
              const name = `alert-${this.context.token}-${this.props.statusId}`;
              this.cookies.set(name, "1", { path: "/" });
            });
        } else if ([8].includes(this.props.statusId)) {
          result.text = "ATTSELF";
          result.text = (
            <>
              Nous sommes en attente des photos de votre véhicule pour réaliser
              l’expertise distance. Nous vous invitons à cliquer sur ce bouton
              pour nous les faire parvenir
              <br />
              <br />
              <Button
                onClick={() => this.getSelfcareWindtransfer("popup")}
                text="Prendre les photos"
              />
            </>
          );
          result.count++;
        } else if ([13].includes(this.props.statusId)) {
          result.text = "ATTREP";
          result.data = [
            {
              type: "radio",
              text: "Souhaitez-vous faire réparer votre véhicule ?",
              1: 1,
              0: 6,
            },
            {
              type: "radio",
              text: "Souhaitez-vous faire remettre en état le véhicule par le réparateur ?",
              1: 2,
              0: 5,
            },
            {
              type: "radio",
              text: "Le rendez-vous de réparation a-t-il été pris avec le réparateur ?",
              1: 3,
              0: 4,
            },
            {
              type: "date",
              text: "Merci de renseigner la date de rendez-vous",
            },
            {
              type: "text",
              text: "Merci de l’information, nous restons dans l’attente de la date de mise en travaux du véhicule afin de valider les éventuels dommages supplémentaires non visibles sans démontage",
            },
            {
              type: "radio",
              text: "Souhaitez-vous remettre en état le véhicule vous-même ?",
              1: 6,
              0: 6,
            },
            {
              type: "text",
              text: "Merci de l’information, nous prévenons l’expert pour qu’il puisse communiquer le rapport d’expertise à votre assurance afin que vous soyez remboursé si les conditions de votre contrat le permettent",
            },
          ];
          result.title =
            "Nous sommes en attente de la date du rendez-vous de réparation. Merci de nous indiquer votre choix";
          result.count++;
          result.onOpen = () =>
            this.setState({ isModalFormEngine: true }, () => {
              const name = `alert-${this.context.token}-${this.props.statusId}`;
              this.cookies.set(name, "1", { path: "/" });
            });
        } else if ([27, 28].includes(this.props.statusId)) {
          result.text = "VRADE";
          result.text = (
            <>
              Attente estimation de la valeur du véhicule par l’expert.
              <br />
              Le montant des réparations est proche ou supérieur à la valeur du
              véhicule qui est en cours d’étude par l’expert.
              <br />
              Si ce n’est pas déjà fait, merci de nous faire parvenir :
              <br />
              - La facture d’achat
              <br />
              - Les PV de contrôle technique
              <br />
              - Les factures d’entretien
              <br />
              <br />
              <ContactLink
                folderId={this.context.id}
                toName={this.context.agency.name}
                toId={this.context.agency.id}
                toSource={"agency"}
                text="Envoyer les documents"
                type="other"
                callback={() => {
                  this.props.insertSituationIxperience();
                  this.setState({ isModal: false }, this.props.onClosePopup);
                }}
              />
            </>
          );
          result.count++;
        } else if (
          (!this.props.dataTimeLine ||
            !this.props.dataTimeLine.filter(
              (element) => element.situationCodeId === 85
            ).length) &&
          [29].includes(this.props.statusId)
        ) {
          result.text =
            "Nous sommes dans l’attente de votre décision : conservation ou cession de votre véhicule.\n Nous vous invitons à consulter les documents dans la messagerie afin de les télécharger, les compléter et nous les renvoyer pour finaliser le traitement de votre dossier.";
          result.count++;
        }
      }

      // GARAGE
      if (this.context.source === "garage") {
        if ([7].includes(this.props.statusId)) {
          result.text = (
            <>
              Nous sommes en attente de votre dossier EAD. Merci de nous faire
              parvenir vos éléments en cliquant ici : <br />
              <br />
              <Button
                onClick={() => this.getSelfcareWindtransfer("popup")}
                text="Prendre les photos"
              />
            </>
          );
          result.count++;
        } else if (
          [14].includes(this.props.statusId) &&
          this.context.sinister.dateWorkEnd &&
          moment().isAfter(
            moment(this.context.sinister.dateWorkEnd, "DD/MM/YYYY")
          )
        ) {
          result.text = "ATTFAC";
          result.text = (
            <>
              Nous sommes en attente de la facture des réparations. Merci de
              nous faire parvenir la facture en cliquant ici : <br />
              <br />
              <ContactLink
                folderId={this.context.id}
                toName={this.context.agency.name}
                toId={this.context.agency.id}
                toSource={"agency"}
                text="Envoyer les documents"
                callback={() => {
                  this.props.insertSituationIxperience();
                  this.setState({ isModal: false }, this.props.onClosePopup);
                }}
                type="invoice"
              />
            </>
          );
          result.count++;
        } else if ([13].includes(this.props.statusId)) {
          result.text = "ATTREP";
          result.data = [
            {
              type: "date",
              text: "Merci de renseigner la date de rendez-vous : ",
            },
            {
              type: "date",
              text: "Merci de renseigner la date de fin des travaux prévisible : ",
            },
          ];
          result.title =
            "Nous sommes en attente de la date du rendez-vous de réparation.";
          result.count++;
          result.onOpen = () =>
            this.setState({ isModalFormEngine: true }, () => {
              const name = `alert-${this.context.token}-${this.props.statusId}`;
              this.cookies.set(name, "1", { path: "/" });
            });
        }
      }
    }

    return result;
  };

  getSelfcareWindtransfer = (from) => {
    getSelfcareWindtransferExpertiseSolution(
      {
        clientName: this.context.client.name,
        reference: this.context.sinister.reference,
        agencyMail: this.context.agency.email,
        licensePlate: this.context.vehicle.licensePlate,
        companyId: this.context.principal.companyId,
        from,
      },
      (result) => {
        if (result.success) {
          this.setState({
            isModalWindTransfer: true,
            urlWindTransfer: result.url,
          });
        } else {
          alert("Une erreur est survenue");
        }
      }
    );
  };

  insertPdfWebservice = (dataHTML, files, data) => {
    let type = 145;

    if (
      this.context.source === "client" &&
      this.checkAlert().text === "ATTREP"
    ) {
      type = 121;

      if (data.currentPage === 4 && !data["date-3"]) {
        type = 352;
      } else if (data.currentPage === 6) {
        type = 351;
      }
    }

    insertPdfWebservice(
      {
        folderId: this.context.id,
        data: dataHTML,
        title: this.checkAlert().title,
        files,
        type,
      },
      (result) => {
        if (result.success) {
          this.props.insertSituationIxperience();
        }
        this.setState({ isLoad: false });
      }
    );
  };

  render() {
    return (
      <>
        <div className="alert">
          <ButtonIcon
            small
            isBackground
            white
            style={{
              height: "46px",
              width: "46px",
              margin: "2px 0 2px 10px",
            }}
            onClick={this.checkAlert().onOpen}
            disabled={!this.checkAlert().count}
          >
            <FontAwesomeIcon icon={faBell} />
          </ButtonIcon>
          <div
            className="alert-notification"
            style={{ display: !this.checkAlert().count ? "none" : "block" }}
          >
            <span>{this.checkAlert().count}</span>
          </div>
        </div>
        <Modal
          show={this.state.isModal}
          title={this.checkAlert().title}
          onClose={() =>
            this.setState({ isModal: false }, this.props.onClosePopup)
          }
        >
          <p>{this.checkAlert().text}</p>
        </Modal>
        <IframeWindTransfer
          show={this.state.isModalWindTransfer}
          url={this.state.urlWindTransfer}
          onClose={(status) => {
            this.setState(
              {
                isModal: false,
                isModalWindTransfer: false,
                urlWindTransfer: null,
              },
              () => {
                if (status) {
                  this.props.insertSituationIxperience();
                }
                this.props.onClosePopup();
              }
            );
          }}
        />
        <FormEngine
          show={this.state.isModalFormEngine}
          onClose={() => {
            this.setState(
              { isModalFormEngine: false },
              this.props.onClosePopup
            );
          }}
          onValid={(result) => {
            this.setState({ isModalFormEngine: false, isLoad: true }, () => {
              this.insertPdfWebservice(
                result.dataHTML,
                result.files,
                result.data
              );
              this.props.onClosePopup();
            });
          }}
          title={this.checkAlert().title}
          data={this.checkAlert().data}
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
