import React from "react";
import { Loader } from "ui-kit-ck-consultant";

import ModalElectronicSignature from "../../components/folder/electronic-signature/ModalElectronicSignature";

import { getExternalElectronicSignature } from "../../requests/other";
import { updateElectronicSignature } from "../../requests/folder";

import logoLang from "../../images/logo-lang.png";

import AuthContext from "../../context/AuthContext";

export default class ExternalElectronicSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModalElectronicSignature: true,
      data: {},
      history: [],
      currentPage: 0,
      agency: {},
    };
  }

  componentDidMount() {
    this.getExternalElectronicSignature();
  }

  getExternalElectronicSignature = () => {
    this.setState({ isLoad: true }, () => {
      const token = this.props.match.params.token;
      const id = this.props.match.params.id;
      getExternalElectronicSignature({ token, id }, (result) => {
        if (result.success) {
          const currentPage = result.dataElectronicSignaturePage.length
            ? result.dataElectronicSignaturePage[
                result.dataElectronicSignaturePage.length - 1
              ]
            : 0;

          let others = [];

          if (
            result.interfaceProprietaire.Nom &&
            result.interfaceProprietaire.Nom.includes(";")
          ) {
            result.interfaceProprietaire.Nom.replace(/\//g, ";")
              .replace(/ \;/g, ";")
              .replace(/\; /g, ";")
              .split(";")
              .forEach((element, idx) => {
                if (idx > 0) {
                  others.push({
                    name: element,
                    email: result.interfaceProprietaire.Email.replace(
                      /\//g,
                      ";"
                    )
                      .replace(/ \;/g, ";")
                      .replace(/\; /g, ";")
                      .split(";")
                      [idx].replace(/ /g, ""),
                    isOk: false,
                  });
                }
              });
          }

          others.push({
            name: result.interfaceClient.Nom,
            email: result.interfaceClient.Email,
            isOk: false,
            isLimit: true,
          });

          this.setState({
            isLoad: false,
            isModalElectronicSignature: currentPage !== 4,
            data: result.dataElectronicSignature.hasOwnProperty("files")
              ? result.dataElectronicSignature
              : {
                  currentDate: result.dataElectronicSignature.currentDate,
                  others,
                  kind: result.interfaceProprietaire.Titre,
                  name: result.interfaceProprietaire.Nom.replace(/\//g, ";")
                    .replace(/ \;/g, ";")
                    .replace(/\; /g, ";")
                    .split(";")[0],
                  address1: result.interfaceProprietaire.Adresse1,
                  address2: result.interfaceProprietaire.Adresse2,
                  zipCode: result.interfaceProprietaire.Code_Postal,
                  city: result.interfaceProprietaire.Ville,
                  files: [],
                  documents: [],
                  iframeUrl: null,
                  otherUrl: null,
                },
            history: result.dataElectronicSignaturePage.length
              ? result.dataElectronicSignaturePage
              : [0],
            currentPage,
            agency: result.agency,
          });
        } else {
          this.setState({
            isLoad: false,
          });
        }
      });
    });
  };

  updateElectronicSignature = (
    dataElectronicSignature = this.state.data,
    dataElectronicSignaturePage = this.state.history,
    isEnd = false,
    callback = () => {}
  ) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        const token = this.props.match.params.token;
        updateElectronicSignature(
          {
            token,
            dataElectronicSignature,
            dataElectronicSignaturePage,
            isEnd,
          },
          (result) => {
            if (result.success && isEnd) {
              this.setState(
                {
                  isLoad: false,
                  isModalElectronicSignature: false,
                },
                callback
              );

              this.props.getMessages();
              this.props.getFiles();
            } else {
              this.setState({ isLoad: false }, callback);
            }
          }
        );
      }
    );
  };

  closeModal = () => {
    this.setState({ isModalElectronicSignature: false }, () => {
      this.updateElectronicSignature();
    });
  };

  updateData = (data, isUpdate = false, callback = () => {}) => {
    this.setState({ data }, () => {
      if (isUpdate) {
        this.updateElectronicSignature(
          data,
          this.state.history,
          false,
          callback
        );
        callback();
      } else {
        callback();
      }
    });
  };

  updatePage = (currentPage, history, isEnd = false, isReset = false) => {
    let data = this.state.data;

    if (isReset) {
      data.documents = [];
      data.files = [];
      data.iframeUrl = null;
      data.otherUrl = null;
    }

    this.setState({ currentPage, history, data }, () => {
      this.updateElectronicSignature(undefined, undefined, isEnd);
    });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          principal: {},
          sinister: {}
        }}
      >
        <>
          {this.state.currentPage === 4 ? (
            <div style={{ width: "100%", height: "100%", display: "flex" }}>
              <div
                style={{
                  margin: "auto",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1>Proposition de cession</h1>
                <img
                  className="mt-10 mb-10"
                  style={{ height: "120px", margin: "auto" }}
                  src={logoLang}
                  alt="lang"
                />
                <p>La signature a été effectuée</p>
              </div>
            </div>
          ) : null}
          <ModalElectronicSignature
            show={this.state.isModalElectronicSignature}
            onClose={this.closeModal}
            data={this.state.data}
            history={this.state.history}
            currentPage={this.state.currentPage}
            updateData={this.updateData}
            updatePage={this.updatePage}
            token={this.props.match.params.token}
            agency={this.state.agency}
          />
          <Loader show={this.state.isLoad} />
        </>
      </AuthContext.Provider>
    );
  }
}
